import { render, staticRenderFns } from "./college-audio-list.vue?vue&type=template&id=28d15923&scoped=true&"
import script from "./college-audio-list.vue?vue&type=script&lang=js&"
export * from "./college-audio-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d15923",
  null
  
)

export default component.exports